import {Box} from "@mui/material";
import ConfigurationMenu from "./ConfigurationMenu";
import {useMemo} from "react";
import MyGridStackSituationalBrief from "./MyGridStackSituationalBrief";

export default function SituationalBriefTilesContainer() {
    const configMenu = useMemo(() => <ConfigurationMenu windowWidthValue='windowWidth' dashboardTilesValue='dashboardTilesSituationalBrief'/>, []);
    return (
        <Box>
            {configMenu}
            <MyGridStackSituationalBrief/>
        </Box>
    )
}