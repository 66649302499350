/**
 * XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX
 *
 * THIS FILE IS MANAGED CENTRALLY BY THE `common-code` REPO.
 * IT COULD BE AUTO-REPLACED AT ANY TIME.
 * DO NOT MAKE CUSTOM CHANGES TO THIS FILE.
 * @see https://gitlab.com/dea-aero/development/common-code
 *
 * XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX
 */
import {Button} from '@mui/material'
import {useEffect, useState} from 'react'
import create from 'zustand'
import CircularProgress from '@mui/material/CircularProgress'
import {useApiCallback} from "./utils/Api";
import {useInterval} from "./utils/useInterval";

export const useLoginStore = create((set, get) => ({
    id: null,
    user: '',
    image: '',
    groups: [],
    loading: false,
    permissions: [],
    loginUserData: {},
    loginCode: null,
    setId: (id) => set({ id }),
    setUser: (user) => set({ user }),
    setImage: (image) => set({ image }),
    setGroups: (groups) => set({ groups }),
    setLoading: (loading) => set({ loading }),
    setPermissions: (permissions) => set({ permissions }),
    setLoginUserData: (userData) => set({userData}),
    setLoginCode: (loginCode) => set({ loginCode }),
    canUser: (method, path) => {
        const {permissions} = get()
        const ret = permissions.some(allowed => {
            if (allowed.method !== method && allowed.method !== '*') {
                return false
            }
            if (allowed.path === path || allowed.path === '*') {
                return true
            }
            if (allowed.path.endsWith('*')) {
                const _allowed_wildcard = allowed.path.replace(/\*$/, '')
                if (path.startsWith(_allowed_wildcard)) {
                    return true
                }
            }
            if (path === '#/') {
                // always allow access to the root page when logged in
                return true
            }
            if (path && path.indexOf('<')) {
                try {
                    const allowed_path_regex = `^${allowed.path}$`
                        .replaceAll(/\//g, '\\/')
                        .replaceAll(/<path:[^>]+>/g, '[^<*]+')  // <path:xyz> matches aaa/bbb/ccc
                        .replaceAll(/<[^>]+>/g, '[^/]+')  // <xyz> matches any chars without a slash
                        .replaceAll('*', '.*')  // * matches anything
                    if (path.match(allowed_path_regex)) {
                        console.log('YES', path, allowed_path_regex)
                        return true
                    }
                } catch (e) {
                    console.error('error in canUser', e)
                    return false
                }
            }
            return false
        })
        return Boolean(ret)
    },
    canGroup: (method, path, permissions) => {
        if (path === '#/') {
            // always allow access to the root page when logged in
            return true
        }
        const ret = permissions.some(allowed => {
            if (allowed.method === method || allowed.method === '*') {
                if (allowed.path === path || allowed.path === '*') {
                    return true
                }
                try {
                    const allowed_path_regex = `^${allowed.path}$`
                        .replaceAll(/\//g, '\\/')
                        .replaceAll(/<path:[^>]+>/g, '[^<*]+')
                        .replaceAll(/<[^>]+>/g, '[^/]+')
                        .replaceAll('*', '.*')
                    if (path && path.match(allowed_path_regex)) {
                        console.log('YES', path, allowed_path_regex)
                        return true
                    }
                } catch (e) {
                    console.error('error in canGroup', e)
                    return false
                }
            }
            return false
        })
        return Boolean(ret)
    },
}))


export function LoginState() {

    const [loginState, setLoginState] = useState({})
    const {setUser} = useLoginStore();

    const loginStateCheck = useApiCallback('GET', '/api/check_login_state', {onDone: setLoginState, ignoreErrors: true})
    useEffect(loginStateCheck, [loginStateCheck])

    useEffect(() => {
        if (!loginState?.is_logged_in) {
            setUser(null)
        }
    }, [loginState, setUser]);

    useInterval(loginStateCheck, 5000)

    return <></>
}


export function Login() {
    const loginStore = useLoginStore()
    const {setId} = loginStore
    const {user, setUser} = loginStore
    const {image, setImage} = loginStore
    const {loading, setLoading} = loginStore
    const {permissions, setPermissions} = loginStore
    const {setLoginUserData} = loginStore
    const {setGroups} = loginStore
    const [loginUrl, setLoginUrl] = useState('')
    const [loginWindow, setLoginWindow] = useState(null)
    const {loginCode, setLoginCode} = loginStore
    const [userData, setUserData] = useState({})

    const code = (loginCode ? `?single_use_code=${loginCode}` : '')
    const loginCheck = useApiCallback('GET', `/api/login${code}`, {setLoading, onDone: setUserData})
    useEffect(loginCheck, [loginCode, loginCheck])

    useEffect(() => {
        if (user) return
        const interval = setInterval(() => {
            if (!loginWindow) return
            if (!loginWindow.location) return
            if (user) return
            let m = []
            try {
                console.log('loginwindow', loginWindow.location)
                m = loginWindow.location.search.match(/^\?single_use_code=(.+)$/)
            } catch (e) {
                console.log('error looking for popup location', e)
                return
            }
            if (m) {
                const _lcode = m[1]
                setLoginWindow(null)
                loginWindow.close()
                console.log('setting loginCode', _lcode)
                setLoginCode(_lcode)
            }
        }, 1000)
        return () => clearInterval(interval)
    }, [loginWindow, setLoginWindow, setLoginCode, user, loginCode])

    useEffect(() => {
        if (userData.hasOwnProperty('redirect') && loginUrl !== userData.redirect) {
            setLoginUrl(userData.redirect)
        }
    }, [userData, setLoginUrl, loginUrl])

    useEffect(() => {
        console.log('userData', userData)
        if (userData.hasOwnProperty('email')) {
            setId(userData.email)
        }
        if (userData.hasOwnProperty('name')) {
            setUser(userData.name)
        }
        if (userData.hasOwnProperty('image')) {
            setImage(userData.image)
        }
        if (userData.hasOwnProperty('groups')) {
            setGroups(userData.groups)
        }
        if (userData.hasOwnProperty('permissions')) {
            setPermissions(userData.permissions)
        }
        setLoginUserData(userData)
    }, [user, setId, image, permissions, setUser, setImage, setPermissions, userData, setLoginUserData, setGroups])

    try {
        if (window.location.search.match(/^\?code=(.+)$/)) {
            // this is the popup login window
            return <div style={{position: 'fixed', top:0, left:0, right:0, bottom:0, backgroundColor:'#333', padding:20}}>
                Processing authentication...
            </div>
        }
    } catch(e) {}

    const promptUserToSignIn = () => {
        const w = window.open(
            loginUrl,
            '__blank',
            'toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=500,height=600'
        )
        setLoginWindow(w)
    }

    return <Button
        disabled={loading || !loginUrl}
        startIcon={loading ? <CircularProgress size="1rem" /> : null}
        onClick={promptUserToSignIn}
    >
        Login
    </Button>

}

export function LogoutProvider({children}) {
    const ls = useLoginStore()
    const setUser = ls.setUser
    const user = ls.user

    const onDone = () => setUser(null)

    const logoutClick = useApiCallback('GET', `/api/logout`, {onDone})

    return user ? <span onClick={() => logoutClick()}>{children}</span> : <></>
}
