import {Box, Stack, Tab, Tabs, useTheme} from '@mui/material';
import TilesContainer from '../component/dashboard/TilesContainer';
import Announcement from '../component/dashboard/Announcement';
import {useState} from 'react';
import SituationalBriefTilesContainer from '../component/dashboard/SituationalBriefTilesContainer';

export default function Home() {
    const theme = useTheme();
    const [tabIndex, setTabIndex] = useState(
        parseInt(window.localStorage.getItem('tiles-tab-index')) || 0
    );

    function handleTabSwitch(e, v) {
        setTabIndex(v);
        window.localStorage.setItem('tiles-tab-index', v);
    }
    
    return (
        <Stack maxWidth='1729px' margin={{xl: 'auto'}} gap='1rem' style={{color: theme.palette.dea.arcticSilver}}>
            <Announcement/>

            <Box sx={{
                '.grid-stack-item>.ui-resizable-se': {
                    backgroundImage: `url("data:image/svg+xml,%3Csvg viewBox='0 0 36 36' color='%23${theme.palette.dea.chipFill.replace('#', '')}' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle fill='currentColor' cx='12' cy='24' r='1.5'/%3E%3Ccircle fill='currentColor' cx='18' cy='24' r='1.5'/%3E%3Ccircle fill='currentColor' cx='18' cy='18' r='1.5'/%3E%3Ccircle fill='currentColor' cx='24' cy='12' r='1.5'/%3E%3Ccircle fill='currentColor' cx='24' cy='24' r='1.5'/%3E%3Ccircle fill='currentColor' cx='24' cy='18' r='1.5'/%3E%3Cpath d='M0 0h36v36H0z' fill='none' /%3E%3C/svg%3E")`,
                    transform: 'rotate(0) translate(4px,3px)', width: '24px', height: '24px',
                    display: {xs: 'none', sm: 'block'}
                }
            }}>
                <Tabs value={tabIndex} centered onChange={handleTabSwitch} aria-label="Tiles dashboards"
                    TabIndicatorProps={{sx: {backgroundColor: theme.palette.dea.turquoise}}}>
                    <Tab label='Your Flight Deck' id='tiles-tab-0' aria-controls='tiles-tabpanel-0'/>
                    <Tab label='Situational Brief' id='tiles-tab-1' aria-controls='tiles-tabpanel-1'/>
                </Tabs>

                {tabIndex === 0 && <CustomTabPanel tabIndex={tabIndex} index={0}>
                    <TilesContainer/>
                </CustomTabPanel>}
                {tabIndex === 1 && <CustomTabPanel tabIndex={tabIndex} index={1}>
                    <SituationalBriefTilesContainer/>
                </CustomTabPanel>}
            </Box>
        </Stack>
    )
}

function CustomTabPanel({tabIndex, index, ...other}) {
    return (
        <Box
            role="tabpanel"
            id={`tiles-tabpanel-${index}`}
            aria-labelledby={`tiles-tab-${index}`}
            {...other}
        />
    );
}