import FlightIcon from '@mui/icons-material/Flight';
import CheckIcon from '@mui/icons-material/Check';
import {Box, Stack, useTheme} from "@mui/material";
import CardContainer from "./CardContainer";
import {useApiCallback} from '../../utils/Api';
import {useEffect, useRef, useState} from 'react';
import {resizeToContentCardContainer} from './GridLayoutValues';
import dayjs from 'dayjs';

export default function MissionSystemsUpdates({apiPath}) {
    const theme = useTheme();
    const ref = useRef(null);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [data, setData] = useState(null);
    const loadTiles = useApiCallback('GET', `/api/${apiPath}`, {
        setLoading, setError,
        onDone: setData,
    })
    useEffect(loadTiles, [loadTiles])

    useEffect(() => {
        let cleanup;

        if (data) {
            cleanup = resizeToContentCardContainer(ref.current, 630, window.myGridSituationalBrief, 'resizeToContentTilesSituationalBrief');
        }

        return () => {
            if (cleanup) cleanup();
        };
    }, [data])

    const {updates = [], employed_since = '', metadata = {}} = data || {}

    return (
        <CardContainer
            ref={ref}
            data={data}
            loading={loading}
            error={error}
            title='Mission Systems Updates'
            icon={<FlightIcon/>}
            iconColor={theme.palette.tiles.red2}
            headerValues={[{heading: 'Last update', value: dayjs(employed_since).format("MM/YYYY")}]}
            metadata={metadata}
        >
            <Box sx={{fontSize: '12px'}}>
                {updates.length > 0
                    ? (
                        <Stack gap='6px'>
                            {updates?.map((update) => (
                                <Box key={update} display='flex' gap='4px'>
                                    {/* <CheckIcon sx={{color: 'dea.turquoise'}} /> */}
                                    <Box>{update}</Box>
                                </Box>
                            ))}
                        </Stack>
                    )
                    : (
                        <Stack alignItems='center' gap='6px'>
                            <CheckIcon sx={{color: 'dea.turquoise'}} />
                            <Box>No Flight Safety Annoucements</Box>
                        </Stack>
                    )}
            </Box>
        </CardContainer>
    )
}