import GroupsIcon from '@mui/icons-material/Groups';
import { Box, Chip, Stack, Typography, useTheme } from "@mui/material";
import CardContainer from "./CardContainer";
import { useEffect, useRef, useState } from 'react';
import { useApiCallback } from '../../utils/Api';
import dayjs from 'dayjs';

import Delivery from '../../images/Delivery.png'
import GroundOperations from '../../images/Ground-operations.png'
import ManagementSystems from '../../images/Management-Systems.png'
import PeopleTeam from '../../images/People-Team.png'
import CAPDEV from '../../images/CAPDEV.png'
import Engineering from '../../images/Engineering.png'
import IntelligenceAnalyst from '../../images/Intelligence-Analyst.png'
import PMO from '../../images/PMO.png'
import Pilots from '../../images/Pilots.png'
import TaskSpecialist from '../../images/Task-Specialist.png'
import it from '../../images/it.png'
import {resizeToContentCardContainer} from './GridLayoutValues';

const images = {
    'CAPDEV': CAPDEV,
    'Delivery ': Delivery,
    'Engineering': Engineering,
    'Ground Operations': GroundOperations,
    'People Team': PeopleTeam,
    'Pilots': Pilots,
    'PMO': PMO,
    'Task Specialist': TaskSpecialist,
    'IT': it,
    'Management Systems': ManagementSystems,
    'Intelligence Analyst': IntelligenceAnalyst,
}

function dateOrImmediate(dateStr) {
    return (dayjs(dateStr).isBefore(dayjs())) ? "Immediate" : dateStr;
}

export default function Vacancies({ apiPath }) {
    const theme = useTheme();
    const ref = useRef(null);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [data, setData] = useState(null);
    const loadTiles = useApiCallback('GET', `/api/${apiPath}`, {
        setLoading, setError,
        onDone: setData,
    })
    useEffect(loadTiles, [loadTiles])

    useEffect(() => {
        let cleanup;

        if (data) {
            cleanup = resizeToContentCardContainer(ref.current);
        }

        return () => {
            if (cleanup) cleanup();
        };
    }, [data])

    const {top_vacancies = [], links = {}, total_open_vacancies = 0, metadata = {}} = data || {}

    return (
        <CardContainer
            ref={ref}
            loading={loading}
            data={data}
            error={error}
            title="Vacancies"
            icon={<GroupsIcon/>}
            iconColor={theme.palette.tiles.lightBlue}
            headerValues={[{heading: 'Open vacancies', value: total_open_vacancies}]}
            footerLinks={[{
                text: 'View All Vacancies',
                href: links?.view_all_vacancies?.uri
            }]}
            metadata={metadata}
        >
            <Box gap='12px' display='grid' gridTemplateColumns='repeat(3, 1fr)' gridTemplateRows='1fr'>
                {top_vacancies?.map((dashboard) => (
                    <Stack key={dashboard?.title} gap='6px' alignItems='center' textAlign='center' height='100%'>
                        <Box width='50px' height='50px' borderRadius='1000px' overflow='hidden' src={images[dashboard?.department]} alt={dashboard?.title} component='img' />
                        <Chip label={`${dashboard?.count} position${dashboard?.count > 1 ? 's' : ''}`} fontSize='11px' size='small' color='success'/>
                        <Box fontSize='12px'>
                            {dashboard?.title}
                            {(dashboard?.start_dates.length > 0) && <Typography fontSize='10px' lineHeight='14.3px'>
                                Start date: {dateOrImmediate(dashboard?.start_dates[0])}
                            </Typography>}
                        </Box>
                    </Stack>
                ))}
            </Box>

        </CardContainer >
    )
}