/**
 * XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX
 *
 * THIS FILE IS MANAGED CENTRALLY BY THE `common-code` REPO.
 * IT COULD BE AUTO-REPLACED AT ANY TIME.
 * DO NOT MAKE CUSTOM CHANGES TO THIS FILE.
 * @see https://gitlab.com/dea-aero/development/common-code
 *
 * XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX
 */
/* @uncommon - @FIXME needs removing for integration to common-code */
import Stack from "@mui/material/Stack"
import {useLoginStore} from "../Login";
import {Chip, MenuItem, Select} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {useEffect, useState} from "react";
import {useGlobalAppTheme} from "../utils/useGlobalAppTheme";

function AuthorityChip({authority}) {
    const props = {
        sx: {width:70, m:'0.5px', mr:2},
        label: authority,
        size: 'small',
    }
    if (authority === 'owner') {
        props.color = "success"
    }
    else if (authority === 'manager') {
        props.variant = "outlined"
        props.color = "success"
    }
    return <Chip {...props}/>
}

export default function Preferences() {
    const {groups} = useLoginStore()

    const {globalAppTheme, setGlobalAppTheme} = useGlobalAppTheme()
    const [applicationTheme, setApplicationTheme] = useState(globalAppTheme)

    useEffect(() => {
        console.log("Application theme was updated to " + applicationTheme)
        setGlobalAppTheme(applicationTheme)
    }, [setGlobalAppTheme, applicationTheme])

    return <Stack sx={{fontSize: {xs: '0.8em', sm: '0.9em', md: '1em'}}} color='dea.invertedJetBlack'>
        <Box container>
            <Typography variant="h5">My Google Groups</Typography>
            <em>Your permissions within this system are derived from these memberships.</em>
            <Box mt={3}>
                {Object.keys(groups).map(groupemail => (
                    <Box sx={{display: 'flex'}} key={groupemail}>
                        <AuthorityChip authority={groups[groupemail]}/>
                        {groupemail}
                    </Box>
                ))}
            </Box>

            <Typography variant="h5" mt={3}>Colour Theme</Typography>
            {/*
            // Removed because of error
            <GenericSelect
                hideBlankOption={true}
                setValue={setApplicationTheme}
                value={applicationTheme}
                label="Application Theme"
                options={[{ code: 'system', label: "Use System Theme" }, { code: 'light', label: "Light" }, { code: 'dark', label: "Dark" }]}
                disableClearable={true}
            /> */}
            <Select
                value={applicationTheme}
                onChange={e => setApplicationTheme(e.target.value)}
                sx={{color: "dea.invertedJetBlack", marginTop: 2}}
            >
                <MenuItem value="system">Use System Theme</MenuItem>
                <MenuItem value="light">Light</MenuItem>
                <MenuItem value="dark">Dark</MenuItem>
            </Select>
        </Box>
    </Stack>
}
