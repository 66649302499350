import BusinessIntelligenceDashboards from './BusinessIntelligenceDashboards';
import Contracts from './Contracts';
import DeliveryMeetings from './DeliveryMeetings';
import Tasks from './Tasks';
import CompanyAssetAndSoftwareLicenses from './CompanyAssetAndSoftwareLicenses';
import PilotInformation from './PilotInformation';
import TimesheetDetails from './TimesheetDetails';
import CompanyCard from './CompanyCard';
import Schengen from './Schengen';
import UserDetails from './UserDetails';
import CompanyInformation from './CompanyInformation';
import StaffHandbookAndCompanyVision from './StaffHandbookAndCompanyVision';
import Vacancies from './Vacancies';
import CompanyProjects from './CompanyProjects';
import SuggestBusinessImprovements from './SuggestBusinessImprovements';
import Wellbeing from './Wellbeing';
import DevicesAndSecurity from './DevicesAndSecurity';
import PlaceholderCard from './PlaceholderCard';
import PlaceholderImageTile from "./PlaceholderImageTile";
import TopImageTile from './TopImageTile';
import {useCallback} from "react";
import Benefits from "./Benefits";
// Situational Brief Tiles
import FlightSafety from './FlightSafety';
import WeatherReport from './WeatherReport';
import SeaStateinOperatingArea from './SeaStateinOperatingArea';
import MissionSystemsUpdates from './MissionSystemsUpdates';
import CAMOUpdates from './CAMOUpdates';
import Part145Updates from './Part145Updates';
import LookAhead from './LookAhead';
import LookOvertheShoulder from './LookOvertheShoulder';
import CustomerContracts from './CustomerContracts';
import GamstonAirportDEAHQ from './GamstonAirportDEAHQ';
import AircraftMaintenance from './AircraftMaintenance';
import OnCall from './OnCall';
import EmergingOpportunities from './EmergingOpportunities';
import UpcomingIssues from './UpcomingIssues';
import MeetingsandVisitors from './MeetingsandVisitors';
import LoneWorkers from './LoneWorkers';
import UpcomingTraining from './UpcomingTraining';
import {ErrorBoundary} from 'react-error-boundary';

export default function RenderCard({name, opts}) {

    const fallback = useCallback(({error, resetErrorBoundary}) => (
        <PlaceholderCard resetErrorBoundary={resetErrorBoundary} error={error} title={name}/>
    ), [name])

    const wrap = c => <ErrorBoundary FallbackComponent={fallback}>{c}</ErrorBoundary>

    switch (name) {
        case 'user_details':
            return wrap(<UserDetails apiPath={name}/>)
        case 'devices_security':
            return wrap(<DevicesAndSecurity apiPath={name}/>)
        case 'tasks':
            return wrap(<Tasks apiPath={name}/>)
        case 'timesheet':
            return wrap(<TimesheetDetails apiPath={name}/>)
        case 'schengen':
            return wrap(<Schengen apiPath={name}/>)
        case 'contracts':
            return wrap(<Contracts apiPath={name}/>)
        case 'company_cards':
            return wrap(<CompanyCard apiPath={name}/>)
        case 'assets_licenses':
            return wrap(<CompanyAssetAndSoftwareLicenses apiPath={name}/>)
        case 'business_intelligence':
            return wrap(<BusinessIntelligenceDashboards apiPath={name}/>)
        case 'company_information':
            return wrap(<CompanyInformation apiPath={name}/>)
        case 'projects':
            return wrap(<CompanyProjects apiPath={name}/>)
        case 'planning_meetings':
            return wrap(<DeliveryMeetings apiPath={name}/>)
        case 'internal_policies':
            return wrap(<StaffHandbookAndCompanyVision apiPath={name}/>)
        case 'pilot_information':
            return wrap(<PilotInformation apiPath={name}/>)
        case 'vacancies':
            return wrap(<Vacancies apiPath={name}/>)
        case 'wellbeing':
            return wrap(<Wellbeing apiPath={name}/>)
        case 'benefits':
            return wrap(<Benefits apiPath={name}/>)
        case 'business_improvement_suggestions':
            return wrap(<SuggestBusinessImprovements apiPath={name}/>)
        case 'top_image_tile':
            return wrap(<TopImageTile/>)
        case 'placeholder_image_tile':
            return wrap(<PlaceholderImageTile url={opts.url}/>)
        // Situational Brief Tiles
        case 'flight_safety':
            return wrap(<FlightSafety apiPath={name}/>)
        case 'weather_report':
            return wrap(<WeatherReport apiPath={name}/>)
        case 'sea_state_in_operating_area':
            return wrap(<SeaStateinOperatingArea apiPath={name}/>)
        case 'mission_systems_updates':
            return wrap(<MissionSystemsUpdates apiPath={name}/>)
        case 'camo_updates':
            return wrap(<CAMOUpdates apiPath={name}/>)
        case 'part145_updates':
            return wrap(<Part145Updates apiPath={name}/>)
        case 'look_ahead':
            return wrap(<LookAhead apiPath={name}/>)
        case 'look_over_the_shoulder':
            return wrap(<LookOvertheShoulder apiPath={name}/>)
        case 'customer_contracts':
            return wrap(<CustomerContracts apiPath={name}/>)
        case 'gamston_airport_and_dea_hq':
            return wrap(<GamstonAirportDEAHQ apiPath={name}/>)
        case 'aircraft_maintenance':
            return wrap(<AircraftMaintenance apiPath={name}/>)
        case 'on_call':
            return wrap(<OnCall apiPath={name}/>)
        case 'emerging_opportunities':
            return wrap(<EmergingOpportunities apiPath={name}/>)
        case 'upcoming_issues':
            return wrap(<UpcomingIssues apiPath={name}/>)
        case 'meetings_and_visitors':
            return wrap(<MeetingsandVisitors apiPath={name}/>)
        case 'lone_workers':
            return wrap(<LoneWorkers apiPath={name}/>)
        case 'upcoming_training':
            return wrap(<UpcomingTraining apiPath={name}/>)
        default:
            console.warn('no renderer for card name', name)
            return null;
    }
}
