import {Box, Grid, useTheme} from '@mui/material';
import {useApiCallback} from '../../utils/Api';
import {useEffect, useMemo, useState} from 'react';
import RequestCard from './RequestCard';
import MyGridStack from './MyGridStack';
import PlaceholderCard from './PlaceholderCard';
import ConfigurationMenu from './ConfigurationMenu';

export default function TilesContainer() {
    const theme = useTheme();

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const loadTiles = useApiCallback('GET', `/api/tiles`, {
        setLoading,
        onDone: setData,
    })
    useEffect(loadTiles, [loadTiles])

    const allowedTiles = data.map(tile => tile.tile_name)

    const configMenu = useMemo(() => <ConfigurationMenu />, []);

    if (loading) return <Loading />

    if (!allowedTiles.length && !loading) return <RequestCard />

    return (
        <Box>
            {configMenu}
            <MyGridStack allowedTiles={allowedTiles} />
        </Box>
    )    
}

function Loading() {
    const theme = useTheme();
    return (
        <Grid container spacing={{xs: '24px'}} maxWidth='1728px'
            style={{color: theme.palette.dea.arcticSilver}} padding='24px 8px' justifyContent={{xs: 'center', md: 'flex-start'}} margin={{xl: 'auto'}}>
            {[1, 2, 3, 4, 5, 6, 7, 8].map(n => (
                <Grid key={n} item xs={12} sm={6} md={4} lg={3}>
                    <Box padding='8px'><PlaceholderCard/></Box>
                </Grid>
            ))}
        </Grid>
    )
}