import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import {Box, Chip, Link, useTheme} from "@mui/material";
import CardContainer from "./CardContainer";
import {useEffect, useRef, useState} from 'react';
import {useApiCallback} from '../../utils/Api';
import {resizeToContentCardContainer} from './GridLayoutValues';


const AChip = (props) => <Chip size="small" sx={{color: '#000', backgroundColor: 'primary.main'}} {...props}/>


export default function Contracts({apiPath}) {
    const theme = useTheme();
    const ref = useRef(null);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [data, setData] = useState(null);
    const loadTiles = useApiCallback('GET', `/api/${apiPath}`, {
        setLoading, setError,
        onDone: setData,
    })
    useEffect(loadTiles, [loadTiles])

    useEffect(() => {
        if (data) resizeToContentCardContainer(ref.current, 10000/* Set very to work for all screens*/)
    }, [data])

    const {contracts_information = {}, links = {}, metadata={}} = data || {};

    return (
        <CardContainer
            ref={ref}
            loading={loading}
            error={error}
            title="Contracts"
            icon={<ReceiptLongIcon/>}
            iconColor={theme.palette.tiles.darkBlue}
            headerValues={[{heading: 'Total contracts', value: contracts_information?.total_count}]}
            footerLinks={[{text: 'See more', href: links?.view_all_contracts?.uri}]}
            metadata={metadata}
        >
            <Box component='table' aria-label="Table of contracts" width='100%'>
                <Box component='thead' fontSize='10px'>
                    <Box component='tr' color={theme.palette.gray["500"]}>
                        <Box padding='0 0 3px 0' textAlign='left' component='th'>CONTRACT</Box>
                        <Box padding='0 3px 3px' textAlign='center' component='th'>CUSTOMER</Box>
                        <Box padding='0 3px 3px' textAlign='center' component='th'>CONTRACTOR</Box>
                        <Box padding='0 3px 3px' textAlign='center' component='th'>PID</Box>
                        <Box padding='0 0 3px 0' textAlign='right' component='th'>CONTRACTUAL</Box>
                    </Box>
                </Box>
                <Box component='tbody'>
                    {contracts_information?.contracts?.map((contract) => (
                        <Box
                            component='tr'
                            key={contract.name}
                            fontSize='12px'
                            color={theme.palette.dea.arcticSilver}
                            textAlign='left'
                            fontWeight='medium'
                        >
                            <Box padding='3px 0' component='td'>{contract.name}</Box>

                            <Box padding='3px' textAlign='center' component='td'>{contract.customer}</Box>

                            <Box padding='3px' textAlign='center' component='td'>{contract.contractor}</Box>

                            <Box padding='3px' component='td' textAlign='center'>
                                {contract.links.project_initiation_document.uri.length !== 0 &&
                                    <Link target='_blank' href={contract.links.project_initiation_document.uri}>
                                        <AChip label='View'/>
                                    </Link>
                                }
                            </Box>

                            <Box padding='3px 0' component='td' textAlign='end'>
                                {contract.links.terms.uri.length !== 0 &&
                                    <Link target='_blank' href={contract.links.terms.uri}>
                                        <AChip label='View'/>
                                    </Link>
                                }
                            </Box>

                        </Box>
                    ))}
                </Box>
            </Box>
        </CardContainer>
    )
}