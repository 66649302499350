/**
 * XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX
 *
 * THIS FILE IS MANAGED CENTRALLY BY THE `common-code` REPO.
 * IT COULD BE AUTO-REPLACED AT ANY TIME.
 * DO NOT MAKE CUSTOM CHANGES TO THIS FILE.
 * @see https://gitlab.com/dea-aero/development/common-code
 *
 * XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX XXX
 */
import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu'
import {useTheme} from '@mui/material/styles';
import {useState, useRef, useCallback, useMemo} from "react";
import Tooltip from "@mui/material/Tooltip";
import Avatar from "@mui/material/Avatar";
import {useLoginStore} from "../Login";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ListItemIcon from "@mui/material/ListItemIcon";
import BrowserUpdatedIcon from "@mui/icons-material/BrowserUpdated";
import RefreshIcon from "@mui/icons-material/Refresh";
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import LogoutIcon from "@mui/icons-material/Logout";
import {getServer} from "../utils/server";
import {useServiceWorker} from "../useServiceWorker";
import {useNavigate} from "react-router-dom";
import {useAppConfigStore} from "../AppConfigStore";
import CloseIcon from '@mui/icons-material/Close';
import List from "@mui/material/List";
import {useApiCallback} from "../utils/Api";
import {Box, Button} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import {useGlobalAppTheme} from "../utils/useGlobalAppTheme";
import WebAssetOutlinedIcon from '@mui/icons-material/WebAssetOutlined';
import VersionStr from "./VersionStr";
import SubpagesMenu from './SubpagesMenu';

/* @uncommon - @FIXME needs removing for integration to common-code */
const UserMenu = () => {
    const server = getServer()
    const theme = useTheme();
    const navigate = useNavigate()
    const [open, setOpen] = useState(false);
    const {helplink} = useAppConfigStore()
    const userMenuAnchorElement = useRef();
    const {updateReady, reloadPage} = useServiceWorker()
    const loginStore = useLoginStore()
    const {globalAppTheme, setGlobalAppTheme} = useGlobalAppTheme()

    const handleToggle = (event) => {
        setOpen(!open);
    };

    const openHelpClick = useCallback(() => {
        window.open(helplink, "_blank")
        setOpen(false)
    }, [helplink])

    const preferencesClick = useCallback(() => {
        navigate('preferences')
        setOpen(false)
    }, [navigate])

    const welcomeText = useMemo(() => {
        let name = loginStore.user
        let firstName = name.split(" ")[0]
        return `Hi, ${firstName}!`
    }, [loginStore])

    const userAvatarSrc = useMemo(() => `${server}/api/person/avatar/${loginStore.id}`, [server, loginStore.id])

    const onLogoutComplete = useCallback(() => {
        console.log("logged out")
        loginStore.setUser(null)
    }, [])
    const logoutClick = useApiCallback('GET', `/api/logout`, { onDone: onLogoutComplete })
    const handleLogoutClick = useCallback(() => {
        logoutClick()
    }, [loginStore])

    // Change theme drop down stuff
    const [anchorElChangeTheme, setAnchorElChangeTheme] = useState(null)
    const changeThemeOpen = Boolean(anchorElChangeTheme)
    const handleClickOpenChangeTheme = (event) => {
        setAnchorElChangeTheme(event.currentTarget)
    };
    const handleCloseChangeTheme = () => {
        setAnchorElChangeTheme(null)
    };
    const handleChangeTheme = (theme) => {
        setGlobalAppTheme(theme)
        setAnchorElChangeTheme(null)
    };
    const getMenuWidth = (anchorEl) => {
        if (!anchorEl) return null
        return anchorEl.clientWidth + 'px' // Get anchor element width and add px
    };

    // Subpages drop down stuff
    const [subpageAnchorEl, setSubpageAnchorEl] = useState(null);
    const subpageAnchorElOpen = Boolean(subpageAnchorEl);

    return <>
        <Tooltip title="Open settings">
            <IconButton onClick={handleToggle} ref={userMenuAnchorElement} sx={{ m: 0, ml: 2, mr: 2 }}>
                <Avatar alt={loginStore.user} src={userAvatarSrc} variant="circular" />
            </IconButton>
        </Tooltip>
        <Menu
            id="menu-usermenu"
            anchorEl={() => userMenuAnchorElement.current}
            open={open}
            onClose={handleToggle}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
            sx={{
                '& .MuiPaper-root': {
                    borderRadius: '24px',
                    padding: '8px',
                    paddingTop: '0px',
                    backgroundColor: theme.palette.dea.jetBlack,
                    backgroundImage: 'none',
                    borderWidth: '10px',
                    borderStyle: 'solid',
                    borderColor: theme.palette.dea.stealthGrey,
                    width: '322px'
                },
                '& .MuiList-root': {
                    backgroundColor: theme.palette.dea.jetBlack,
                    paddingLeft: "8px",
                    paddingRight: "8px",
                }
            }}
        >
            <ListItem sx={{ padding: 0 }}>
                <Typography variant="caption" color="dea.invertedJetBlack" sx={{ textAlign: 'center', margin: '0 auto', paddingLeft: '15px' }}>
                    {loginStore.id}
                </Typography>

                <IconButton onClick={() => setOpen(false)} sx={{ float: 'right', width: '30px', overflow: 'visible' }}>
                    <CloseIcon />
                </IconButton>
            </ListItem>

            <ListItem>
                <Avatar alt={loginStore.user} src={userAvatarSrc} variant="circular" sx={{ margin: '0 auto', height: '64px', width: '64px' }} />
            </ListItem>

            <ListItem>
                <ListItemText sx={{ margin: '0 auto' }}>
                    <Typography variant="body1" color="dea.arcticSilver" align="center" sx={{ textAlign: 'center', margin: '0 auto' }}>
                        <strong>{welcomeText}</strong>
                    </Typography>
                </ListItemText>
            </ListItem>

            <Divider sx={{ marginTop: '5px', marginBottom: '10px' }} />

            <Button
                onClick={handleClickOpenChangeTheme}
                sx={{
                    color: theme.palette.dea.invertedJetBlack,
                    backgroundColor: theme.palette.dea.stealthGrey,
                    justifyContent: 'space-between',
                    width: '100%',
                    textTransform: 'none !important',
                    marginBottom: '10px',
                    borderRadius: '8px',
                    borderColor: 'none',
                    boxShadow: 'none',
                }}
            >
                <Box display='flex' gap='32px' textTransform='capitalize'>
                    <DarkModeOutlinedIcon /> Appearance: {globalAppTheme}
                </Box>
                <ArrowDropDownIcon />
            </Button>
            <Menu
                anchorEl={anchorElChangeTheme}
                open={changeThemeOpen}
                onClose={handleCloseChangeTheme}
                PaperProps={{
                    sx: { width: getMenuWidth(anchorElChangeTheme) },
                }}
                sx={{
                    '& .MuiPaper-root': { backgroundColor: 'dea.jetBlack', padding: '6px', borderRadius: '8px', width: '267px' },
                    '& .MuiList-root': { backgroundColor: 'transparent', color: 'dea.invertedJetBlack', fontSize: '12px', gap: '1px', display: 'flex', flexDirection: 'column' },
                    '& .MuiMenuItem-root': { gap: '32px', padding: '8px 10px', backgroundColor: 'dea.stealthGrey', '&:hover': { backgroundColor: 'dea.chipFill' } }
                }}
            >
                <MenuItem sx={{ borderRadius: '8px 8px 0 0' }} onClick={() => handleChangeTheme('system')}><DarkModeOutlinedIcon /> Use System Theme</MenuItem>
                <MenuItem onClick={() => handleChangeTheme('light')}><LightModeOutlinedIcon /> Light</MenuItem>
                <MenuItem sx={{ borderRadius: '0 0 8px 8px' }} onClick={() => handleChangeTheme('dark')}><DarkModeOutlinedIcon /> Dark</MenuItem>
            </Menu>
            {/* Subpages */}
            <div>
                <Button
                    id='subpages-button'
                    onClick={(e) => setSubpageAnchorEl(e.currentTarget)}
                    aria-haspopup="true"
                    aria-controls={subpageAnchorElOpen ? 'subpages-menu' : undefined}
                    aria-expanded={subpageAnchorElOpen ? 'true' : undefined}
                    sx={{
                        color: theme.palette.dea.invertedJetBlack,
                        backgroundColor: theme.palette.dea.stealthGrey,
                        justifyContent: 'space-between',
                        width: '100%',
                        textTransform: 'none !important',
                        marginBottom: '10px',
                        borderRadius: '8px',
                        borderColor: 'none',
                        boxShadow: 'none',
                    }}
                >
                    <Box display='flex' gap='32px' textTransform='capitalize'>
                        <WebAssetOutlinedIcon /> Company subpages
                    </Box>
                    <ArrowDropDownIcon />
                </Button>
                <Menu
                    id='subpages-menu'
                    anchorEl={subpageAnchorEl}
                    open={subpageAnchorElOpen}
                    onClose={() => setSubpageAnchorEl(null)}
                    MenuListProps={{ 'aria-labelledby': 'subpages-button' }}
                    sx={{
                        '& .MuiPaper-root': {
                            backgroundColor: 'dea.jetBlack',
                            padding: '8px 16px',
                            borderRadius: '8px',
                            width: '280px',
                        },
                        '& .MuiList-root': {
                            backgroundColor: 'transparent',
                            color: 'dea.invertedJetBlack',
                            fontSize: '12px',
                        }
                    }}
                >
                    <SubpagesMenu />
                </Menu>
            </div>

            <List className="user-menu-list" sx={{
                '& .MuiListItem-root': {
                    backgroundColor: `${theme.palette.dea.stealthGrey}`,
                    paddingTop: '0px',
                    marginTop: '1px',
                    marginBottom: '1px',
                    height: '36px',
                    cursor: 'pointer',
                    color: theme.palette.dea.invertedJetBlack,
                    '&:hover': {
                        backgroundColor: theme.palette.dea.chipFill,
                    }
                },
                '& .MuiListItem-root:first-of-type': {
                    paddingTop: '2px',
                    borderTopLeftRadius: '8px', /* Curved top-left corner for first item */
                    borderTopRightRadius: '8px' /* Curved top-right corner for first item */
                },
                '& .MuiListItem-root:last-of-type': {
                    paddingBottom: '3px',
                    borderBottomLeftRadius: '8px', /* Curved bottom-left corner for last item */
                    borderBottomRightRadius: '8px' /* Curved bottom-right corner for last item */
                }
            }}>
                {updateReady ? <ListItem key='UpdateReady' sx={{color:theme.palette.success.main}} onClick={_ => {
                    reloadPage();
                    setOpen(false)
                }}>
                    <ListItemIcon>
                        <BrowserUpdatedIcon/>
                    </ListItemIcon>
                    <ListItemText>Update Available</ListItemText>
                </ListItem> : ''}
                <ListItem key='Reload' onClick={_ => {
                    reloadPage();
                    setOpen(false)
                }}>
                    <ListItemIcon>
                        <RefreshIcon sx={{ color: 'dea.invertedJetBlack' }} />
                    </ListItemIcon>
                    <ListItemText>Reload App</ListItemText>
                </ListItem>
                <ListItem key="Help" onClick={openHelpClick}>
                    <ListItemIcon>
                        <HelpOutlineOutlinedIcon sx={{ color: 'dea.invertedJetBlack' }} />
                    </ListItemIcon>
                    <ListItemText>
                        Help
                    </ListItemText>
                </ListItem>
                <ListItem key="Preferences" onClick={preferencesClick}>
                    <ListItemIcon>
                        <ManageAccountsOutlinedIcon sx={{ color: 'dea.invertedJetBlack' }} />
                    </ListItemIcon>
                    <ListItemText>
                        Preferences
                    </ListItemText>
                </ListItem>

                <ListItem key='Logout' onClick={handleLogoutClick}>
                    <ListItemIcon>
                        <LogoutIcon sx={{ color: 'dea.invertedJetBlack' }} />
                    </ListItemIcon>
                    <ListItemText>Sign out</ListItemText>
                </ListItem>
            </List>
            <VersionStr />
        </Menu>
    </>
};

export default UserMenu;
